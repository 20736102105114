import Aboutus from "./icons/info.png";
import tc from "./icons/terms-and-conditions.png";
import refund from "./icons/refund.png";
import feedback from "./icons/feedback.png";
import privacy from "./icons/compliant.png";
import pricing from "./icons/dollar.png";
import contactus from "./icons/contact-mail.png";
import home from "./icons/home.png";

const NAV_ITEMS = [
  {
    name: "Home",
    path: "/",
    icon: home,
  },
  {
    name: "About Us",
    path: "/about",
    icon: Aboutus,
  },
  {
    name: "Pricing",
    path: "/pricing",
    icon: pricing,
  },
  {
    name: "Contact Us",
    path: "/contact",
    icon: contactus,
  },
  {
    name: "Feedback",
    path: "/feedback",
    icon: feedback,
  },
  {
    name: "Policies",
    path: "/policies",
    icon: tc,
  },
];

  
export default NAV_ITEMS;
  