import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LocationPicker from './locationPicker'; // Import the LocationPicker component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from 'react-router-dom'; 


function ContactForm({ parentCallback1,setuserdetails}) {
  // console.log('in contact form');
  // console.log(isuserdetails);
  // const navigate = useNavigate();
  const [userLocation, setUserLocation] = useState(null);
  const [showMap, setMapStatus] = useState(null);
  // isuserdetails=true;
  // console.log(isuserdetails);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      contact: '',
      address: '',
      longitude: null,
      latitude: null
    });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleLocationSelected = (location) => {
    setFormData((prevData) => ({
      ...prevData,
      location,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted:', formData);
    const myObj = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      contact: formData.contact,
      address: formData.address,
    }
    navigate('/home');
    // You can add code here to send the data to a backend server
  };
  const setLocationFromMap = (lat,long)=>{
    console.log('inside setLocationFromMap');
    // console.log(lat);
    // console.log(formData);
    const myObj = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      contact: formData.contact,
      address: formData.address,
      longitude: lat,
      latitude: long
    }
    return myObj
  }
  const parentFunction1 = (myObj)=>{
    
    console.log(parentCallback1);
    parentCallback1(myObj);
  }
  function getUserLocation(e) {
    e.preventDefault();
    console.log('Form submitted:', formData);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  return (
    <div style={{ textAlign: 'center', maxWidth: '700px', margin: '0 auto', padding: '30px' }}>
      <h2>Contact Form</h2>
      <form onSubmit={handleSubmit} style={{ display: 'grid', gap: '15px', marginTop: '20px' }}>
        <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr 1fr' }}>
          <div style={{ marginRight: '10px', paddingRight: '10px' }}>
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
              style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize: '16px' }}
            />
          </div>
          <div>
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
              style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize: '16px' }}
            />
          </div>
        </div>
        <div>
          <label htmlFor="contact">Contact:</label>
          <input
            type="text"
            id="contact"
            name="contact"
            value={formData.contact}
            onChange={handleInputChange}
            required
            pattern="[0-9]{10}" // Phone number pattern (10 digits)
            title="Please enter a valid 10-digit phone number"
            style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize: '16px' }}
          />
        </div>
        <div>
          <label htmlFor="address">Address:</label>
          <textarea
            id="address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            required
            style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize: '18px' }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <button
            type="submit"
            style={{
              padding: '10px 20px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
            }}
            onClick={async ()=>{
              // console.log(`in user details ${isuserdetails}`);
              await setuserdetails(true);
              let obj = setLocationFromMap({lat: 0.0, long: 0.0});
              parentFunction1(obj);
              // console.log(`in user details ${isuserdetails}`);
            }}
          >
            Submit
          </button>
          <span style={{ marginTop: '10px', fontSize: '14px' }}>OR</span>
          {userLocation ? (
            <LocationPicker lati={userLocation.latitude} longi={userLocation.longitude} parentCallback={setLocationFromMap} parentFunctionOfRoot = {parentFunction1}/>
          ) : (
            <button
              onClick={getUserLocation}
              style={{
                padding: '8px 20px',
                backgroundColor: 'white',
                color: '#007bff',
                border: '1px solid #007bff',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '14px',
                marginTop: '10px',
              }}
            >
              Use My Current Location
            </button>
          )}
        </div>
      </form>
    </div>
  );
  
}

export default ContactForm;

