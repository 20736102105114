import { Auth } from "aws-amplify";
import toast from "react-hot-toast";
import "./UserDisplay.css";
import google from "../SignInButton/icons/google.png";

const signInUser = async (event) => {
  event.preventDefault();

  try {
    await Auth.federatedSignIn({ provider: "Google" });
  } catch (error) {
    toast.error("Oops 😕! Login got Failed ! Please Try Again. ");
    console.log(error);
  }
};

const modal = document.createElement("div");
modal.classList.add("modal");
document.body.appendChild(modal);

const content = document.createElement("div");
content.classList.add("modal-content");
modal.appendChild(content);

const loginText = document.createElement("p");
loginText.textContent = "Please sign in to continue";
content.appendChild(loginText);

const okButton = document.createElement("button");
okButton.type = "button";
okButton.classList.add("google-btn");
okButton.addEventListener("click", signInUser);

const img = document.createElement("img");
img.src = google;
img.alt = "google-signin";
img.classList.add("googleimg");

okButton.appendChild(img);
content.appendChild(okButton);

modal.style.display = "none";
modal.style.justifyContent = "center";
modal.style.alignItems = "center";

export { modal };
