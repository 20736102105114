import React, { useContext } from "react";
import { userContext } from "../../Context";
import { Auth } from "aws-amplify";
import toast from "react-hot-toast";
import "./Account.css";

const Account = () => {
  const { currentUser, setCurrentUser } = useContext(userContext);

  const signOutUser = async (event) => {
    event.preventDefault();
    try {
      localStorage.setItem("currentUser", null);
      toast.success(`You're logged out `, {
        icon: "👋",
      });
      await Auth.signOut();
      setCurrentUser(null);
    } catch (error) {
      toast.success("Oops 😕! Something went wrong ! Try to Logout again !");
      console.log(error);
    }
  };

  return (
    <div className="account-container">
      <h1>Account</h1>
      <div className="account-details">
        <div>
          <div className="label">Email:</div>
          <div className="value">{currentUser.attributes.email}</div>
        </div>
        {/* Add other account information here */}
      </div>
      <button onClick={signOutUser} className="sign-out-button">
        Sign Out
      </button>
    </div>
  );
};

export default Account;
