
import { Link } from "react-router-dom";


const countno = document.createElement('div');
countno.classList.add('modal');
document.body.appendChild(countno);

// Create the content of the modal box
const content = document.createElement('div');
content.classList.add('modal-content');
countno.appendChild(content);

// Create the "Log in" text
const loginText = document.createElement('p');
loginText.textContent = 'We are experiencing high load please switch to paid plan for seamless experience';
content.appendChild(loginText);

// Create the "OK" button
const okButton = document.createElement('button');
okButton.textContent = 'OK';
content.appendChild(okButton);
const cancelButton = document.createElement('button');
cancelButton.textContent = 'Cancel';
content.appendChild(cancelButton);

// Center the modal box on the screen
countno.style.display = 'flex';
countno.style.justifyContent = 'center';
countno.style.alignItems = 'center';
if (parseInt(localStorage.getItem("count")) === 750) {
    // console.log("equal 50");
    countno.style.display = 'flex';
  }

  else if (parseInt(localStorage.getItem("count")) > 750 && (parseInt(localStorage.getItem("count")) - 750) % 4 == 0) {
    // console.log("greater than 50+4");
    countno.style.display = 'flex';
  }
  else{
    countno.style.display = 'none';
  }
const cancelclicked=(event)=>{
    event.preventDefault();
    countno.style.display = 'none';

}
const okclicked=(event)=>{
    event.preventDefault();
    
    window.location.href = '/pricing';
    countno.style.display = 'none';
}
okButton.addEventListener('click', okclicked);
cancelButton.addEventListener('click', cancelclicked);
export{countno};