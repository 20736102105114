import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import UserDisplay from "../UserDisplay/UserDisplay";
import { verticle_nav } from "../../Context";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import "./Navbar.css";
import logo from '../../assets/char_jini_nbg.png'
import NAV_ITEMS from './navItems';

const Navbar = () => {
  const { isverticle, setnav } = useContext(verticle_nav);
  const [show, handleShow] = useState(false);
  const [count2, setCount2] = useState(0);
  const showNavOnScroll = () => {
    const topBarHeight = 0; // Adjust this value based on the actual height of your top bar
    if (window.scrollY > topBarHeight) {
      handleShow(true);
    } else {
      handleShow(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("count") === null) {
      localStorage.setItem("count", 0);
      setCount2(localStorage.getItem("count"));
    }
    else {
      setCount2(localStorage.getItem("count"));
    }
    window.addEventListener("scroll", showNavOnScroll);
    return () => window.removeEventListener("scroll", showNavOnScroll);

  }, []);

  const handleClick = () => {
    setnav(!isverticle);
    if (localStorage.getItem("count") === null) {
      localStorage.setItem("count", 0);
      setCount2(localStorage.getItem("count"));
    }
    else {
      setCount2(localStorage.getItem("count"));
    }
  }
  const closeMobileMenu = () => setnav(false);

  return (
    <nav className={`navbar ${show || isverticle ? "navbar_scrolled" : ""}`}>
      <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
      <img src={logo} className="nav-logo-icon" alt="" srcset="" /><h1 className="heading">Say Jini</h1>
      </Link>
      <div className="menu-icon" onClick={handleClick}>
        {isverticle ? (
          <IoClose className="nav-icons" />
        ) : (
          <GiHamburgerMenu className="nav-icons" />
        )}
      </div>

      <ul className={`${isverticle ? "nav-menu active nav-menu-blur" : "nav-menu"}`}>
        <li className="nav-item account-item" onClick={closeMobileMenu}>
          <UserDisplay count2={count2} />
        </li>

        {NAV_ITEMS.map((link, index) => (
          <li className="nav-item" key={index}>
            <Link to={link.path} className="nav-links" onClick={closeMobileMenu}>
              <img src={link.icon} alt="" className="navimg" /> {link.name}
            </Link>
          </li>
        ))}

      </ul>
    </nav>
  );
};

export default Navbar;
