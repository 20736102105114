import React from 'react';
import './CancellationRefundPolicy.css';

const CancellationRefundPolicy = () => {
  document.body.style.height = "100vh";
  return (
    <div className="cancellation-refund-container">
      <h1>Cancellation & Refund Policy</h1>
      <p>
        We strive to provide our users with the best possible experience. We understand that circumstances may arise that require you to cancel your subscription. This Cancellation and Refund Policy outlines the terms and conditions under which you may cancel your subscription and request a refund.
      </p>
      <h2>Subscription Model</h2>
      <p>
        Our subscription model offers access to our services for a certain fee per user per month based on the chosen plan. This fee is billed monthly and provides uninterrupted access to our platform and its features as mentioned in the plan details. 
      </p>
      <h2>Cancellation Policy</h2>
      <p>
        You may cancel your subscription at any time by following the cancellation process in your account settings. Upon cancellation, your access to our services will continue until the end of the current billing period. No further charges will be applied to your account after the cancellation date.
      </p>
      <h2>Refund Policy</h2>
      <p>
        You may request a refund within 24 hours of the initial purchase. In this case, you will receive a full refund of the monthly subscription fee. If you cancel your subscription, you will not be charged again. However, you will not be refunded the fees paid for the month in which you cancel your subscription.
      </p>
      <h2>Refund Request Process</h2>
      <p>
        To request a refund, please contact our support team at d@datawithd.com with the following information:
        <br></br>1. Your full name and email address associated with your account.
        <br></br>2. The reason for your refund request.
        <br></br>3. The date of your initial purchase or renewal.
        <br></br>Our support team will review your request and respond within 3-5 business days. If your request is approved, the refund will be processed within 7-10 business days.
      </p>
      <h2>Changes to the Cancellation and Refund Policy</h2>
      <p>
        Our company reserves the right to modify this Cancellation and Refund Policy at any time. Any changes to the policy will be posted on our website and will be effective immediately upon posting. Your continued use of our services after any changes to the policy constitutes your acceptance of the revised policy.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns regarding our Cancellation and Refund Policy, please feel free to contact us at d@datawithd.com.
      </p>
    </div>
  );
};

export default CancellationRefundPolicy;
