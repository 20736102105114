import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  document.body.style.height = "100vh";
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy discloses the privacy practices for our website. This
        privacy policy applies solely to information collected by this website. It will
        notify you of the following:
      </p>
      <ul>
        <li>What personally identifiable information is collected from you through the website</li>
        <li>How it is used and with whom it may be shared</li>
        <li>What choices are available to you regarding the use of your data</li>
        <li>The security procedures in place to protect the misuse of your information</li>
        <li>How you can correct any inaccuracies in the information</li>
      </ul>
      <p>
        If you feel that we are not abiding by this privacy policy, you should contact us
        immediately.
        {/* sayjini00@gmail.com. */}
      </p>
    </div>
  );
};

export default PrivacyPolicy;
