import React from "react";
import { Link } from "react-router-dom";
import tc from "../Navbar/icons/terms-and-conditions.png";
import refund from "../Navbar/icons/refund.png";
import privacy from "../Navbar/icons/compliant.png";
import "./Policies.css";

const policies = [
  {
    name: "Privacy Policy",
    path: "/policies/privacyPolicy",
    icon: privacy,
  },
  {
    name: "Terms & Conditions",
    path: "/policies/TermsConditions",
    icon: tc,
  },
  {
    name: "Refund Policy",
    path: "/policies/CancellationRefundPolicy",
    icon: refund,
  },
];

const Policies = () => {
  return (
    <div className="policies">
      <h1 className="policies-title">Policies</h1>
      <div className="policies-container">
        {policies.map((policy, index) => (
          <Link key={index} to={policy.path} className="policy-tile">
            <img src={policy.icon} alt={policy.name} className="policy-icon" />
            <h3 className="policy-name">{policy.name}</h3>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Policies;
