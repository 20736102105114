import React from "react";
import "./SignInButton.css";
import { Auth } from "aws-amplify";
import google from "../SignInButton/icons/google.png";
import toast from "react-hot-toast";

const SignInButton = () => {
  const signInUser = async (event) => {
    event.preventDefault();

    try {
      await Auth.federatedSignIn({ provider: "Google" });
    } catch (error) {
      toast.error("Oops 😕! Login got Failed ! Please Try Again. ");
      console.log(error);
    }
  };

  return (
    <button onClick={signInUser} type="button" className="google-btn">
      <img src={google} alt="google-signin" className="googleimg" />
    </button>
  );
};

export default SignInButton;
