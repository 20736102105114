import React, { useState } from 'react';
import './Feedback.css';

const Feedback = () => {

  document.body.style.height = "100%";

 

  return (
    <section id="contact-us">
      <h2>Feedback</h2>
      <iframe id="contact-us-form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSfk9DE7zaT9X9n3jg_ItEXESEXdRng5xxH1BvSuOg2RQPdkEQ/viewform?embedded=true"
       
        width="100%"
        // height="100vh"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    </section>
  );
};

export default Feedback;
