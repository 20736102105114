import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Account from "./components/Account/Account";
import Aboutus from "./components/AboutUs/Aboutus";
import ContactUs from "./components/ContactUs/Contactus";
import PricingPage from "./components/pricing/PricingPage";
import PrivacyPolicy from "./components/privacypage/PrivacyPage";
import TermsConditions from "./components/TermsConditions/TermsConditions";
import CancellationRefundPolicy from "./components/CancellationRefundPolicy/CancellationRefundPolicy";
import Feedback from "./components/Feedback/Feedback";
import UserDetails from "./components/userDetails/userDetails";
import Policies from "./components/Policies/Policies"; // Import the Policies component
import OrderHistory from "./components/orderHistory"
import { userContext, verticle_nav } from "./Context";
import { Toaster } from "react-hot-toast";
import "react-chat-elements/dist/main.css";
import "./ChatComponent.css";
import "./App.css";
import * as NoSleep from "nosleep.js";

import { Auth } from "aws-amplify";

function App() {
  async function checkuser() {
    const user = await Auth.currentAuthenticatedUser();
    const signout = await Auth.signOut();
    console.log("user: ", signout);
  }
  const myObj1 = {
    firstName: '',
    lastName: '',
    contact: '',
    address: '',
    latitude: null,
    longitude: null,
    order_timestamps: null,
    order_kart: [],
    total_price:0,
    user:''
  };
  const parentCallback1 = async (myObj)=>{
    if(!myObj)
    return;
    console.log(myObj);
    if (myObj.hasOwnProperty('firstName')) {
      myObj1.firstName = myObj.firstName;
    }
    if (myObj.hasOwnProperty('lastName')) {
      myObj1.lastName = myObj.lastName;
    }
    if (myObj.hasOwnProperty('contact')) {
      myObj1.contact = myObj.contact;
    }
    if (myObj.hasOwnProperty('address')) {
      myObj1.address = myObj.address;
    }
    if (myObj.hasOwnProperty('latitude')) {
      myObj1.latitude = myObj.latitude;
    }
    if (myObj.hasOwnProperty('longitude')) {
      myObj1.longitude = myObj.longitude;
    }
    // Auth.currentAuthenticatedUser().then((user) => {
    //   console.log('user email = ' + user.attributes.email);
    // });
    myObj1.user = (await Auth.currentAuthenticatedUser());
    // // return myObj1;
    myObj1.user=myObj1.user.attributes.email;
    localStorage.setItem('userDetails', JSON.stringify(myObj1));
    console.log(myObj1);
  }
  const getMyOBj=()=>{
    return myObj1;
  }
  
  let isuserdetails = JSON.parse(localStorage.getItem('isUserDetails'));
  const callBackForUserDetails = ()=>{
    console.log('in call back function of user-details');
    localStorage.setItem('isUserDetails', true);
    isuserdetails = JSON.parse(localStorage.getItem('isUserDetails'));
    // setuserdetails(true);
  }
  // parentCallback1();
  const [isverticle, setnav] = useState(false);
  
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : null
  );
  useEffect(() => {
    const noSleep = new NoSleep();
    console.log("No Sleep");
    noSleep.enable();
    localStorage.setItem('isUserDetails', false);
    return () => {
      console.log("Sleep");
      noSleep.disable();
    };
  }, []);
  
  return (
    <div className="App">
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 3000,
          style: {
            borderRadius: "10px",
          },
        }}
      />
      <userContext.Provider value={{ currentUser, setCurrentUser }}>
        <verticle_nav.Provider value={{ isverticle, setnav }}>
          <Navbar />
          <Routes>
            <Route path="/" element={isuserdetails?<Home />:<Home myObj1 getMyOBj={getMyOBj} />} />
            <Route path="/home" element={<Home myObj1 getMyOBj={getMyOBj}/>} />
            <Route path="/about" element={<Aboutus />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/account" element={<Account />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/policies" element={<Policies />} />
            <Route path="/policies/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/policies/TermsConditions" element={<TermsConditions />} />
            <Route path="/policies/CancellationRefundPolicy" element={<CancellationRefundPolicy />} />
            <Route path="/user-details" element={<UserDetails parentCallback1={parentCallback1} setuserdetails={callBackForUserDetails}/>} />
            <Route path="/order-history" element={<OrderHistory/>} />
          </Routes>
        </verticle_nav.Provider>
      </userContext.Provider>
    </div>
  );
}

export default App;
