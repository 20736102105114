import React from 'react';
import './AboutUs.css';

const Aboutus = () => {
  document.body.style.height = "100vh";
  return (
    <div className="about-us-container">
      <h1>About Us</h1>
      <p>
        Say Jini was built by Data With D, an AI Innovations Lab under Opzen Pvt Ltd. It is manage We are a team of dedicated professionals who are passionate about providing
        high-quality services to our clients. Our company was founded on the principles of
        integrity, hard work, and exceptional customer service, and we are committed to
        upholding these values in everything we do.
      </p>
      <p>
        Our goal is to help our clients achieve their objectives by delivering
        innovative solutions that meet their specific needs. We pride ourselves on our
        ability to work closely with our clients to understand their requirements and
        provide personalized services that exceed their expectations.
      </p>
      <p>
        Whether you are looking for software development, consulting, or other
        services, we are here to help. Contact us today to learn more about how we can
        assist you with your next project.
      </p>
    </div>
  );
};

export default Aboutus;
