import React from 'react';
import './TermsConditions.css';

const TermsConditions = () => {
  
  // Get the height of the content
//   let contentHeight = Math.max(
//     document.body.scrollHeight,
//     document.body.offsetHeight,
//     document.documentElement.clientHeight,
//     document.documentElement.scrollHeight,
//     document.documentElement.offsetHeight
//   );
  
//   // Get the height of the viewport
//   let viewportHeight = window.innerHeight;


// // Check if the content height is greater than the viewport height
// if (contentHeight > viewportHeight) {
//   console.log('There is a need to scroll.');
//   document.body.style.height = "100%";
// } else {
//   console.log('There is no need to scroll.');
//   document.body.style.height = "100vh";
// }
document.body.style.height = "100vh";
  return (
    <div className="terms-conditions-container">
      
      <h1>Terms & Conditions</h1>
      <p>
      Welcome to Say Jini website. By using our website, you agree to be bound by these Terms and Conditions ("Terms"). Please read them carefully. If you do not agree to these Terms, please do not use our website.
      </p>
      <h2>Intellectual Property</h2>
      <p>
      All content on our website, including but not limited to text, graphics, images, videos, and software, is the property of the company and is protected by intellectual property laws. You may not use any of our content without our express written consent.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        Our company shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use our website or the information contained therein. Our company is not responsible for any damages or losses that may result from the use of our website or the accuracy, completeness, or reliability of any information provided on the website.
      </p>
      <h2>Data collection and usage</h2>
      <p>
        The company may collect anonymized data from your use of our voice assistant for the purposes of training and improving our AI technology. However, we will not share any personal or secure information with third parties. By using our voice assistant, you acknowledge and agree to the collection and use of anonymized data as described in this section.
      </p>
      <h2>Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless our company and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your access to or use of our website.
      </p>
      <h2>Modification of Terms</h2>
      <p>
        The company reserves the right to modify these Terms at any time without prior notice. Your continued use of our website constitutes your acceptance of the modified Terms.
      </p>
      <h2>Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about these Terms or our website, please contact us at d@datawithd.com
      </p>
    </div>
  );
};

export default TermsConditions;
