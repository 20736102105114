
import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Make sure to have axios or any other HTTP library installed

const OrderHistory = ({ loggedInCustomerId }) => {
  const [orderHistory, setOrderHistory] = useState([]);

  useEffect(() => {
    // Fetch order history data for the logged-in customer
    const fetchOrderHistory = async () => {
      try {
        const response = [{
            "email": "yashagrawal0601@gmail.com",
            "phone": "7850046500",
            "address": "Dholpur",
            "latitude": "9.96233",
            "longitude": "50.76891",
            "order_timestamps": "yyyy.MM.dd HH:mm:ss",
            "order_kart": [{
              "name": "potato",
              "quantity": 4,
              "unit": "kg"
            }],
            "total_price": 70,
            "payment_mode": "COD",
            "order_status": "False",
            "order_id": "y45hs7"
          },
          {
            "email": "yashagrawal0601@gmail.com",
            "phone": "7850046500",
            "address": "Dholpur",
            "latitude": "9.96233",
            "longitude": "50.76891",
            "order_timestamps": "yyyy.MM.dd HH:mm:ss",
            "order_kart": [{
              "name": "potato",
              "quantity": 4,
              "unit": "kg"
            }],
            "total_price": 70,
            "payment_mode": "COD",
            "order_status": "False",
            "order_id": "y45hs7"
          }
        ]

        setOrderHistory(response);
      } catch (error) {
        console.error('Error fetching order history:', error);
      }
    };

    fetchOrderHistory();
  }, [loggedInCustomerId]);

  return (
    <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <h2>Order History</h2>
      {orderHistory.map((order, index) => (
        <div key={index} style={{ border: '1px solid Grey', borderRadius: '8px', padding: '10px', marginBottom: '10px' }}>
          <p><strong>Email:</strong> {order.email}</p>
          <p><strong>Phone:</strong> {order.phone}</p>
          <p><strong>Address:</strong> {order.address}</p>
          <p><strong>Order Timestamp:</strong> {order.order_timestamps}</p>
          <div style={{ marginBottom: '10px' }}>
            <strong>Order Items:</strong>
            <ul>
              {order.order_kart.map((item, itemIndex) => (
                <li key={itemIndex}>{item.quantity} {item.unit} of {item.name}</li>
              ))}
            </ul>
          </div>
          <p><strong>Total Price:</strong> ${order.total_price}</p>
          <p><strong>Payment Mode:</strong> {order.payment_mode}</p>
          <p><strong>Order Status:</strong> {order.order_status ? 'Completed' : 'Pending'}</p>
          <p><strong>Order ID:</strong> {order.order_id}</p>
        </div>
      ))}
    </div>
  );
};

export default OrderHistory;