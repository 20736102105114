import React, { useState } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { useNavigate } from 'react-router-dom';

function LocationPicker({ google, onLocationSelected,lati,longi,parentCallback,parentFunctionOfRoot }) {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const navigate = useNavigate();
  const [pointer,setPointer] = useState(null);
  console.log(`latitude : ${lati}`);
  console.log(`longitude: ${longi}`);
  const handleMapClick = (mapProps, map, clickEvent) => {
    const { latLng } = clickEvent;
    setSelectedLocation({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });
    // onLocationSelected({ lat: latLng.lat(), lng: latLng.lng() });
  };
  const handleConfirmLocation = () => {
    if (selectedLocation) {
        console.log('aryan in selected location');
        console.log(selectedLocation)
        const myObj = parentCallback(selectedLocation.lat,selectedLocation.lng);
        console.log(`myObj ${JSON.stringify(myObj)}`);
        parentFunctionOfRoot(myObj);
      navigate('/home');
    }
  };

//   return (
//     <div>
//   <h2>Select Location</h2>
//   <Map
//     google={google}
//     zoom={50}
//     style={{ width: '100vw', height: '100vh' }}
//     containerStyle={{ position: 'fixed', zIndex: 9999 }}
//     initialCenter={{ lat: lati, lng: longi }}
//     onClick={handleMapClick}
//   >
//     {selectedLocation ? (
//       <Marker position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }} />
//     ) : (
//       <Marker position={{ lat: lati, lng: longi }} />
//     )}
//   </Map>
// </div>

//   );
return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 9999 }}>
      <Map
        google={google}
        zoom={15}
        style={{ width: '100%', height: '100%' }}
        initialCenter={{ lat: lati, lng: longi }}
        onClick={handleMapClick}
      >
        {selectedLocation ? (
      <Marker position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }} />
    ) : (
      <Marker position={{ lat: lati, lng: longi }} />
    )}
      </Map>
      <button
        onClick={handleConfirmLocation}
        style={{
          position: 'fixed',
          bottom: '10px',
          left: '50%',
          transform: 'translateX(-50%)',
          padding: '8px 16px',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          zIndex: 99999,
        }}
      >
        Confirm Location
      </button>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDuWPwOEtSfu_GvbmCBtQogntXdlE8m3Qo',
})(LocationPicker);
