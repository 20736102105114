import { useEffect, useContext } from "react";
import { userContext } from "../../Context";
import { Auth } from "aws-amplify";
import SignInButton from "../SignInButton/SignInButton";
import defaultProfile from "./icons/default-profile.png";
import toast from "react-hot-toast";
import "./UserDisplay.css";
import { modal } from "./loginprompt";
import { useNavigate } from "react-router-dom";

const UserDisplay = (props) => {
  const { currentUser, setCurrentUser } = useContext(userContext);
  const navigate = useNavigate();

  const signInUser = async (event) => {
    event.preventDefault();

    try {
      await Auth.federatedSignIn({ provider: "Google" });
    } catch (error) {
      toast.error("Oops 😕! Login got Failed! Please Try Again.");
      console.log(error);
    }
  };

  const checkCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      if (user) {
        setCurrentUser(user);
        localStorage.setItem("currentUser", JSON.stringify(user));
        toast.success(`Welcome to Say Jini! You're logged in`, { icon: "👏" });
        modal.style.display = "none";
      } else {
        displayWelcomeMessage();
      }
    } catch (error) {
      displayWelcomeMessage();
      console.log(error);
    }
  };

  const displayWelcomeMessage = () => {
    toast.success(`Welcome to Say Jini!`, { icon: "👏" });
    modal.style.display = "flx";
  };

  const signOutUser = async (event) => {
    event.preventDefault();
    try {
      localStorage.setItem("currentUser", null);
      toast.success(`You're logged out`, { icon: "👋" });
      modal.style.display = "flx";
      await Auth.signOut();
    } catch (error) {
      toast.success("Oops 😕! Something went wrong! Try to Logout again!");
      console.log(error);
    }
  };

  const truncateText = (text, len = 35) => {
    if (!text) {
      return "Super User";
    }
    if (text.length <= len) return text;
    else return text.substring(0, len) + "...";
  };

  useEffect(() => {
    if (!currentUser) checkCurrentUser();
    const storedUser = localStorage.getItem("currentUser");
    modal.style.display = storedUser && storedUser !== "null" ? "none" : "flx";
  }, [currentUser]);

  const goToAccount = () => {
    navigate("/account");
  };

  const getUserProfileImage = () => {
    return currentUser?.photoURL || defaultProfile;
  };

  return (
    <>
      {currentUser ? (
        <div className="account" onClick={goToAccount}>
          <div className="account-info">
            <img
              src={getUserProfileImage()}
              alt="Profile"
              className="profile-pic"
            />
            <div className="user-details">
              <p className="user-name">
                {truncateText(currentUser.attributes.name)}{" "}
                {/* <span className="arrow-icon">{">"}</span> */}
              </p>
              <p className="user-email">
                {truncateText(currentUser.attributes.email)}
              </p>
            </div>
          </div>
          <div className="account-stats">
            <div className="stat-tile">
              <div className="stat">{750 - props.count2}</div>
              <div className="stat-name">Credits left</div>
            </div>
            <div className="stat-tile">
              <div className="stat"> Free </div>
              <div className="stat-name">User</div>
            </div>
            <div className="stat-tile">
              <div className="stat"> {props.count2} / 750 </div>
              <div className="stat-name">Used</div>
            </div>
          </div>
        </div>
      ) : (
        <SignInButton />
    )}
  </>
);
};

export default UserDisplay;

