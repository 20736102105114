import React, { useState } from "react";
import "./ContactUsForm.css";

function ContactUs() {
  document.body.style.height = "100%";
  

  return (
    <section id="contact-us">
      <h2>Contact Us</h2>
      <iframe id="contact-us-form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSdeGzgpDkDWAXDDKuEyr2t1R16SAObAx4KupAepUhX77HinDg/viewform?embedded=true"
       
        width="100%"
        // height="100vh"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
      <p>
       If you have any questions or concerns about our website or services, please contact us using the above form or you may use the information below.<br></br>
       Address: Data with D, 808B, Golden Leaf, Tonk Road, Jaipur, Rajasthan, India <br></br>
       Email: d@datawithd.com
      </p>
    </section>
  );
}

export default ContactUs;
