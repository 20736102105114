import React, { useEffect } from 'react';
import Stripe from 'stripe';
import "./PricingPage.css";
import{countno} from "../Home/count"
const stripe = Stripe('pk_live_51MoSrSSFUMUuyZFz5OeHrJcdN5rgiuIb3bMOFwkK5WvU1kPBRajru8BOKsSy6zTYyz1hivaczu4GoSUcxK5kTXVv00CF6G9r0j');

function PricingTable() {
  // document.body.style.height = "100vh";
  // window.onload = function() {
  //   // this code will execute after the page has fully loaded
  //   console.log('The /pricing page has fully loaded.');
  //   countno.style.display = 'none';
  // };
  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);
    console.log('The /pricing page has fully loaded.');
    countno.style.display = 'none';
  }, []);
  

  return (
    <section id="pricing-page">
      <h2>Pricing</h2>
    <stripe-pricing-table 
      pricing-table-id="prctbl_1Moh18SFUMUuyZFzgHlbO68I"
      publishable-key="pk_live_51MoSrSSFUMUuyZFz5OeHrJcdN5rgiuIb3bMOFwkK5WvU1kPBRajru8BOKsSy6zTYyz1hivaczu4GoSUcxK5kTXVv00CF6G9r0j"
      
    />
     </section>
  );
  
}

export default PricingTable;
